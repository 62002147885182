var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('validation-observer',{ref:"observer"},[_c('form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Asset Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Asset Number","required":"","outlined":"","dense":""},model:{value:(_vm.batteries.asset_number),callback:function ($$v) {_vm.$set(_vm.batteries, "asset_number", $$v)},expression:"batteries.asset_number"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Serial Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Serial Number","required":"","outlined":"","dense":""},model:{value:(_vm.batteries.serial_number),callback:function ($$v) {_vm.$set(_vm.batteries, "serial_number", $$v)},expression:"batteries.serial_number"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Entry Cycles","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Entry Cycles","required":"","outlined":"","dense":""},model:{value:(_vm.batteries.entry_cycles),callback:function ($$v) {_vm.$set(_vm.batteries, "entry_cycles", $$v)},expression:"batteries.entry_cycles"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.battery_status,"error-messages":errors,"item-text":"name","item-value":"name","menu-props":"auto","label":"Status","outlined":"","required":"","dense":"","prepend-inner-icon":_vm.icons.mdiHeartPulse},model:{value:(_vm.batteries.status),callback:function ($$v) {_vm.$set(_vm.batteries, "status", $$v)},expression:"batteries.status"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Compatible Models","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.aircraft_types,"error-messages":errors,"item-text":"name","item-value":"name","menu-props":"auto","label":"Compatible Models","outlined":"","multiple":"","dense":"","chips":"","prepend-inner-icon":_vm.icons.mdiEvPlugType1},model:{value:(_vm.batteries.compatible_models),callback:function ($$v) {_vm.$set(_vm.batteries, "compatible_models", $$v)},expression:"batteries.compatible_models"}})]}}])})],1)],1),_c('v-btn',{staticClass:"ma-10 pa-5",attrs:{"type":"submit","color":"primary"}},[_vm._v(" Update ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }